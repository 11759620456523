<template>
    <div class="aboutUs">
        <div class="banner">
            <Banner :adCategory="3"></Banner>
        </div>
        <div class="module1">
            <div class="content">
                <CourseTitle title="学校简介" contText=""> </CourseTitle>
                <div class="schoolIntroduction flex-center">
                    <div class="school_img">
                        <div class="">
                            <div class="school_imgs pointer">
                                <video :poster="formData.schoolVideoUrl" :src="formData.schoolVideoUrl"
                                    class="icon_img pointer" id="myVideo" v-if="videoShow == true" controls
                                    playsinline="true" autoplay></video>
                                <img v-if="videoShow == false" :src="formData.schoolCover" alt="" />
                                <img v-if="videoShow == false" @click="startBtn" class="icon_video"
                                    src="@/assets/img/newHome/icon_video.png" alt="" />
                            </div>
                        </div>
                    </div>

                    <div class="school_details">
                        <div class="schoolName">{{ formData.schoolName }}</div>
                        <div class="details" v-html="formData.introduction"></div>
                    </div>
                </div>
                <div class="schoolIntroduction_box flex">
                    <div class="schoolIntroduction_list display column" v-for="(item, index) in IntroductionList"
                        :key="index">
                        <img :src="item.imgUrl" alt="" />
                        <div class="name">{{ item.name }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="six_training">
            <div class="content">
                <CourseTitle title="培训与服务" :contText="formData.sixTrainingIntroduce">
                </CourseTitle>
                <div class="six_training_main flex">
                    <div class="flex flex-wrap">
                        <div class="six_training_list" v-for="(item, index) in formData.aboutSixTrainingVOS"
                            :key="index" :style="{ backgroundImage: `url(${item.trainingImgUrl})` }"
                            @click="toCourse(item, index)">
                            <div class="mask"></div>
                            <div class="cont flex-center column">
                                <div class="list_title">{{ item.trainingName }}</div>
                                <div class="list_line"></div>
                                <div class="list_content display">
                                    {{ item.trainingContent }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <TeachingEnvironment type="关于我们" :title="homeData.homeChooseGejVOS[5].title"
            :titleDescribe="homeData.homeChooseGejVOS[5].titleDescribe" :list="homeData.homeTeachingEnvironmentVOMaps"
            :courseList="courseList"></TeachingEnvironment>

        <div class="lecturerList">
            <div class="content">
                <div class="content_Title display column">
                    <div class="title">名师团队</div>
                    <div class="cont_text">{{ homeData.homeChooseGejVOS[1].title }}</div>
                    <div class="cont_text">
                        {{ homeData.homeChooseGejVOS[1].titleDescribe }}
                    </div>
                </div>
                <div class="swiper_box">
                    <swiper class="swiper" ref="swiper" :options="swiperOption">
                        <swiper-slide v-for="(item, index) in homeData.teacherVOS" :key="index">
                            <div class="list" @mouseover="lecturerMouseOver(index)" @mouseleave="lecturerMouseLeave">
                                <img class="swiper_img" :src="item.lecturerAvatar" alt="" />

                                <div class="introduction_box" v-if="lecturerIndex == index">
                                    <div class="lecturer_name">{{ item.lecturerName }}</div>
                                    <div class="line"></div>
                                    <div class="instructor_experience" v-html="item.honor"></div>
                                </div>
                                <div class="cont" v-else>
                                    <div class="name">{{ item.lecturerName }}</div>
                                    <div class="workingExperience row">
                                        {{ item.introduction }}
                                    </div>
                                </div>
                            </div>
                        </swiper-slide>

                        <div class="swiper-button-prev" slot="button-prev">
                            <img class="icon_left_right" src="@/assets/img/home/left1.png" alt="" />
                        </div>
                        <div class="swiper-button-next" slot="button-next">
                            <img class="icon_left_right" src="@/assets/img/home/right1.png" alt="" />
                        </div>
                    </swiper>
                </div>
            </div>
        </div>

        <div class="school_advantage">
            <div class="content">
                <CourseTitle title="学校优势" :contText="formData.schoolAdvantageIntroduce">
                </CourseTitle>
                <div class="school_advantage_mian display">
                    <div class="list display pointer" :class="activeIndex == index ? 'active' : ''"
                        v-for="(item, index) in formData.aboutSchoolAdvantageVOS" :key="index"
                        @click="schoolAdvantageIntroduceBtn(index)">
                        <div class="cont_text">
                            {{ item.schoolAdvantageContent }}
                        </div>
                    </div>
                </div>
                <div class="school_advantage_Img display">
                    <img :src="formData.aboutSchoolAdvantageVOS[activeIndex]
                .schoolAdvantageImgUrl
                " alt="" />
                </div>
            </div>
        </div>
        <div class="module2">
            <div class="content remarks_box">
                <CourseTitle title="校长寄语" contText="校企一体、产教融合、共同进取、开拓创新">
                </CourseTitle>
                <div class="remarks" v-html="formData.messagePresident"></div>
            </div>
        </div>
        <div class="module3">
            <div class="content">
                <CourseTitle title="荣誉资质" contText="共同探索、勇往直前、乘风破浪、开拓未来">
                </CourseTitle>
                <div class="carousel_box">
                    <el-carousel :interval="400000" type="card" height="400px">
                        <el-carousel-item v-for="item in formData.honorVOS" :key="item.id">
                            <div class="el_carousel_div flex-center column">
                                <img class="icon_img" :src="item.honorUrl" alt="" />
                                <div class="qualification">{{ item.introduce }}</div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
        </div>
        <div class="module4">
            <div class="content">
                <div class="course_Title display column">
                    <div class="title">校园文化</div>
                    <div class="cont_text">{{ formData.schoolCultureIntroduce }}</div>
                </div>
                <div class="school_culture flex">
                    <div class="cont_box" v-for="(item, index) in schoolCultureList" :key="index">
                        <div class="cont"></div>
                        <div class="school_culture_list flex-center column">
                            <div class="title">{{ item.cultureName }}</div>
                            <div class="culture" v-html="item.cultureContent"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module5">
            <div class="content">
                <div class="title display">联系我们</div>
                <div class="contact flex">
                    <div class="fl">
                        <div class="list flex">
                            <img class="icon_img" src="@/assets/img/contactUs/1.png" alt="" />
                            <div class="contact_way">
                                <div>报名热线</div>
                                <div>{{ $store.state.Information.telephone }}</div>
                            </div>
                        </div>
                        <div class="list flex">
                            <img class="icon_img" src="@/assets/img/contactUs/2.png" alt="" />
                            <div class="contact_way">
                                <div>邮箱</div>
                                <div>{{ $store.state.Information.email }}</div>
                            </div>
                        </div>
                        <div class="list flex">
                            <img class="icon_img" src="@/assets/img/contactUs/3.png" alt="" />
                            <div class="contact_way">
                                <div>邮政编码</div>
                                <div>{{ $store.state.Information.zipCode }}</div>
                            </div>
                        </div>
                        <div class="list flex">
                            <img class="icon_img" src="@/assets/img/contactUs/4.png" alt="" />
                            <div class="contact_way">
                                <div>学校地址</div>
                                <div>
                                    {{ $store.state.Information.schoolAddress }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="fr">
                        <!-- <iframe class="icon_map"
                            src="https://map.baidu.com/@12725892.334499454,2580974.66792332,17z/latlng%3D22.715282%252C114.317392%26title%3D%25E6%2588%2591%25E7%259A%2584%25E4%25BD%258D%25E7%25BD%25AE%26content%3D%25E6%259E%259C%25E5%25B0%2594%25E4%25BD%25B3%25E8%2581%258C%25E4%25B8%259A%25E6%258A%2580%25E8%2583%25BD%25E5%259F%25B9%25E8%25AE%25AD%25E5%25AD%25A6%25E6%25A0%25A1%26autoOpen%3Dtrue&ak='CEGnqD2sksloOAoq8zgiqbHPzvYqEZsC'"
                            frameborder="0"></iframe> -->
                        <iframe class="icon_map" src="https://www.golkia-school.com/words/schoolMap.html"
                            frameborder="0" scrolling="no"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CountTo from 'vue-count-to'
import Banner from '@/components/banner'
import CourseTitle from './components/courseTitle.vue'
import Lecturer from '@/components/lecturer'
import TeachingEnvironment from '@/components/teachingEnvironment.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
    name: 'aboutUs',
    components: {
        Banner,
        CourseTitle,
        Swiper,
        SwiperSlide,
        CountTo,
        Lecturer,
        TeachingEnvironment,
    },
    data() {
        return {
            videoShow: false,
            activeIndex: 0,
            lecturerIndex: null,
            startVal: 0, // 初始值
            duration: 1500, // 需要滚动的时间
            IntroductionList: [
                {
                    id: 1,
                    name: '建筑产业人才培训基地',
                    imgUrl: require('@/assets/img/aboutUs/1.png'),
                },
                {
                    id: 2,
                    name: '知名品牌，值得信赖',
                    imgUrl: require('@/assets/img/aboutUs/2.png'),
                },
                {
                    id: 3,
                    name: '师资力量雄厚',
                    imgUrl: require('@/assets/img/aboutUs/3.png'),
                },
                {
                    id: 4,
                    name: '因材施教，特色教学',
                    imgUrl: require('@/assets/img/aboutUs/4.png'),
                },
            ],
            honorList: [
                {
                    id: 1,
                    url: require('@/assets/img/aboutUs/ry.png'),
                    qualification: '建筑施工与承包资质',
                },
                {
                    id: 2,
                    url: require('@/assets/img/aboutUs/ry.png'),
                    qualification: '建筑施工与承包资质',
                },
                {
                    id: 3,
                    url: require('@/assets/img/aboutUs/ry.png'),
                    qualification: '建筑施工与承包资质',
                },
                {
                    id: 4,
                    url: require('@/assets/img/aboutUs/ry.png'),
                    qualification: '建筑施工与承包资质',
                },
            ],
            schoolCultureList: [],
            percentageList: [
                {
                    id: 1,
                    name: '理论知识',
                    num: 20,
                },
                {
                    id: 2,
                    name: '技术示范',
                    num: 20,
                },
                {
                    id: 3,
                    name: '实操练习',
                    num: 50,
                },
                {
                    id: 4,
                    name: '职业道德',
                    num: 10,
                },
            ],
            bannerList: [
                {
                    id: 1,
                    url: require('@/assets/img/aboutUs/banner1.png'),
                },
                {
                    id: 2,
                    url: require('@/assets/img/aboutUs/bg1.png'),
                },
            ],
            sixTraining: [],
            schoolAdvantage: [],
            formData: {},
            homeData: {},
            lecturerData: {},
            swiperOption: {
                slidesPerView: 4,
                spaceBetween: 26,
                direction: 'horizontal',
                observer: true,
                observeParents: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                on: {
                    resize: () => {
                        this.$refs.swiper.$swiper.changeDirection(
                            window.innerWidth <= 400 ? 'vertical' : 'horizontal'
                        )
                    },
                },
            },
        }
    },
    mounted() {
        this.getData()
        this.getCultureAPI()
        this.getHomeData()
        this.getTeacher()
        document.documentElement.scrollTop = 0
        document.getElementById('TANGRAM__PSP_44__').style.display = 'none'
    },
    methods: {
        //获取首页信息
        getHomeData() {
            this.api.findHomeData().then((res) => {
                if (res.code == 0) {
                    this.homeData = res.data
                    this.courseList = this.homeData.homeTeachingEnvironmentVOMaps[0].value
                }
            })
        },
        //获取金牌讲师
        getTeacher() {
            this.api.findTeacherData().then((res) => {
                this.lecturerData = res.data.teacherVOS
            })
        },
        getData() {
            this.api.findAboutUsData().then((res) => {
                if (res.code == 0) {
                    this.formData = res.data
                    console.log(this.formData.schoolVideoUrl, 8888888)
                }
            })
        },
        getCultureAPI() {
            this.api.findSchoolCulture().then((res) => {
                if (res.code == 0) {
                    this.schoolCultureList = res.data
                }
            })
        },
        //联系客服
        service() {
            document.getElementById('nb-frame-chat-box').style.display = 'block'
        },
        schoolAdvantageIntroduceBtn(index) {
            this.activeIndex = index
        },
        toCourse(item, index) {
            let workData = this.$store.state.workData
            let type = null
            let typeId = null
            let url = ''
            workData.forEach((res) => {
                if (item.trainingName == res.classificationName) {
                    type = res.type
                    typeId = res.id
                }
            })

            if (item.trainingName == '特种作业') {
                url = '/homes/specialOperations'
            } else if (item.trainingName == '执业资格') {
                url = '/homes/registered'
            } else if (item.trainingName == '技能培训') {
                url = '/homes/constructionSite'
            } else if (item.trainingName == '安全培训') {
                url = '/homes/safetyTraining'
            } else if (item.trainingName == '学历提升') {
                url = '/homes/educationBg'
            }
            this.$router.push({
                path: url,
                query: {
                    id: typeId,
                    ids: 1,
                },
            })
        },
        // 讲师详情移入
        lecturerMouseOver(index) {
            this.lecturerIndex = index
        },
        // 讲师详情移出
        lecturerMouseLeave() {
            this.lecturerIndex = null
        },
        //点击播放视频
        startBtn() {
            this.videoShow = true
            let myVideo = document.getElementById('myVideo')
            myVideo.play()
            console.log(11111111111)
        },
    },
}
</script>
<style lang="scss" scoped>
.aboutUs {
    .module1 {
        padding-bottom: 100px;

        .schoolIntroduction {
            margin-top: 30px;

            .school_img {
                img {
                    width: 554px;
                    height: 364px;
                }
            }

            .school_details {
                margin-left: 27px;

                .schoolName {
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                    line-height: 24px;
                    margin-top: 4px;
                }

                .details {
                    width: 597px;
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #666666;
                    line-height: 28px;
                    margin-top: 20px;
                }
            }
        }

        .schoolIntroduction_box {
            margin-top: 35px;

            .schoolIntroduction_list:last-child {
                margin-right: 0px !important;
            }

            .schoolIntroduction_list {
                width: 288px;
                height: 199px;
                background: #fafafa;
                margin-right: 15px;

                img {
                    width: 80px;
                    height: 93px;
                }

                .name {
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                    margin-top: 16px;
                }
            }
        }
    }

    .module2 {
        min-height: 668px;
        background: url('../../assets/img/aboutUs/bg1.png') no-repeat;
        background-size: 100% 100%;
        padding-bottom: 50px;

        .remarks_box {
            .remarks {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #666666;
                margin-top: 50px;
                line-height: 30px;
            }

            .headmasterName {
                margin-top: 50px;
                display: flex;
                justify-content: flex-end;

                .name {
                    width: 84px;
                    height: 30px;
                }
            }
        }
    }

    .module3 {
        height: 872px;

        .carousel_box {
            margin-top: 97px;
        }

        .el_carousel_div {
            .icon_img {}
        }

        ::v-deep.el-carousel--horizontal {
            padding-top: 20px;
            padding-left: 50px;
            width: 1180px;
            margin: 0 auto;
        }

        ::v-deep.el-carousel__arrow--right {
            right: 50px;
        }

        ::v-deep.el-carousel {
            .el-carousel__item--card.is-in-stage {
                width: 501px;
                height: 376px;
                box-shadow: 2px 3px 29px 0px rgba(0, 0, 0, 0.25);
            }

            .el-carousel__item--card {
                width: 400px;
                height: 312px;
                box-shadow: 2px 3px 29px 0px rgba(0, 0, 0, 0.25);

                img {
                    width: 430px;
                    height: 320px;
                    margin-top: 30px;
                }
            }
        }

        ::v-deep.el-carousel__item--card.is-active {
            width: 501px;
            height: 376px;
            box-shadow: 2px 3px 29px 0px rgba(0, 0, 0, 0.25);

            .el_carousel_div {
                width: 501px;
                height: 376px;
                background: white;

                img {
                    width: 430px;
                    height: 320px;
                    margin-top: 30px;
                }

                .qualification {
                    font-size: 14px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                }
            }
        }
    }

    .module4 {
        height: 656px;
        background: url('../../assets/img/aboutUs/bg2.png') no-repeat;
        background-size: 100% 100%;

        .course_Title {
            padding-top: 98px;

            .title {
                font-size: 32px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #ffffff;
                line-height: 32px;
            }

            .cont_text {
                width: 700px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                line-height: 16px;
                text-align: center;
                margin-top: 26px;
            }
        }

        .school_culture {
            .cont_box:nth-child(1) {
                .culture {
                    padding-top: 10px !important;
                }
            }

            .cont_box:nth-child(2) {
                .cont {
                    background: #ff881e;
                }

                .title {
                    color: white !important;
                }

                .culture {
                    margin-top: 32px !important;
                    color: white !important;
                }
            }

            .cont_box:nth-child(3) {
                .title {}

                .culture {
                    margin-top: 32px !important;
                }
            }

            .cont_box {
                margin-top: 42px;
                position: relative;
                width: 380px;
                height: 300px;
                margin-right: 31px;

                .cont {
                    width: 380px;
                    height: 300px;
                    background: #ffffff;
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 1;
                }

                .school_culture_list {
                    width: 380px;
                    height: 300px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 2;

                    background: url('../../assets/img/aboutUs/bg3.png') no-repeat;
                    background-size: 100% 100%;

                    .title {
                        font-size: 30px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #333333;
                        line-height: 30px;
                        margin-top: 38px;
                    }

                    .culture {
                        padding: 0px 30px;
                        box-sizing: border-box;
                        font-size: 24px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        line-height: 24px;
                    }

                    .list {
                        margin-top: 22px;

                        .originality {
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            color: #333333;
                            display: flex;
                        }

                        .school_motto {
                            width: 230px;
                            font-size: 16px;
                            color: #666;
                            line-height: 22px;
                            margin-top: -6px;
                            margin-left: 6px;
                        }
                    }
                }
            }
        }
    }

    .module5 {
        height: 764px;
        background: url('../../assets/img/aboutUs/bg4.png') no-repeat;
        background-size: 100% 100%;

        .cont_text {
            width: 1088px;
            height: 115px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            line-height: 26px;
            margin: 0 auto;
            margin-top: 50px;
            text-indent: 2em;
        }

        .percentage_box {
            margin-top: 78px;

            .list {
                width: 294px;
                position: relative;

                .line {
                    width: 1px;
                    height: 82px;
                    background: #dedede;

                    position: absolute;
                    top: 6px;
                    right: 0;
                }

                .percentage {
                    font-size: 60px;
                    font-family: Arial;
                    font-weight: bold;
                    color: #003c7e;
                    line-height: 46px;
                }

                .models {
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    line-height: 15px;
                    margin-top: 19px;
                }
            }
        }
    }

    .module6 {
        height: 990px;

        .module_banner {
            margin-top: 43px;
        }

        .btn {
            width: 248px;
            height: 50px;
            background: #ff881e;
            border-radius: 5px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            margin: 0 auto;
            margin-top: 56px;
        }

        ::v-deep.el-carousel__container {
            height: 586px;
        }

        ::v-deep.el-carousel {
            .el-carousel__container {
                .el-carousel__arrow {
                    width: 60px;
                    height: 60px;
                    font-size: 24px;
                    background: rgba(51, 51, 51, 0.5);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }

    .six_training {
        height: 668px;
        background: url('../../assets/img/aboutUs/bg5.png') no-repeat;
        background-size: 100% 100%;

        .six_training_main {
            margin-top: 44px;

            .six_training_list {
                width: 580px;
                height: 360px;

                background-size: 100% 100%;
                position: relative;
                margin-bottom: 14px;
                margin-left: 14px;

                .mask {
                    width: 580px;
                    height: 360px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: #000;
                    opacity: 0.5;
                }

                .cont:hover {
                    background: #003c7e;
                }

                .cont {
                    width: 580px;
                    height: 360px;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 11;

                    .list_title {
                        font-size: 28px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #ffffff;
                        line-height: 28px;
                        margin-top: 58px;
                    }

                    .list_line {
                        width: 134px;
                        height: 2px;
                        background: #ff881e;
                        margin-top: 15px;
                    }

                    .list_content {
                        width: 493px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #ffffff;
                        line-height: 24px;
                        text-align: center;
                        margin-top: 22px;
                    }
                }
            }
        }
    }

    .school_advantage {
        height: 770px;

        .school_advantage_mian {
            position: relative;
            margin-top: 70px;

            .list:hover {
                background: #003c7e;

                .cont_text {
                    color: white !important;
                }
            }

            .active {
                background: #003c7e;

                .cont_text {
                    color: white !important;
                }
            }

            .list:nth-child(1) {
                position: absolute;
                top: 0;
                left: 0;
            }

            .list:nth-child(2) {
                position: absolute;
                top: 0;
                right: 0;
            }

            .list:nth-child(3) {
                position: absolute;
                top: 152px;
                left: 0;
            }

            .list:nth-child(4) {
                position: absolute;
                top: 152px;
                right: 0;
            }

            .list:nth-child(5) {
                position: absolute;
                top: 308px;
                left: 0;
            }

            .list:nth-child(6) {
                position: absolute;
                top: 308px;
                right: 0;
            }

            .list {
                width: 240px;
                height: 130px;
                border: 2px solid #003c7e;
                border-radius: 10px;

                .cont_text {
                    width: 178px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    line-height: 24px;
                    text-align: center;
                }
            }

            .school_advantage_Img {
                img {
                    width: 670px;
                    height: 440px;
                    border-radius: 8px;
                }
            }
        }
    }

    .lecturerList {
        height: 835px;
        background: url('../../assets/img/lecturer/bg1.png') no-repeat;
        background-size: 100% 100%;

        .content_Title {
            padding-top: 98px;

            .title {
                font-size: 36px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #ffffff;
                line-height: 24px;
            }

            .cont_text {
                width: 700px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #ffffff;
                line-height: 24px;
                text-align: center;
                margin-top: 21px;
            }
        }

        .swiper_box {
            margin-top: 63px;
            display: flex;

            ::v-deep.swiper-container {
                height: 420px;
            }

            .icon_left_right {
                width: 48px;
                height: 48px;
            }

            .list {
                width: 280px;
                height: 420px;
                position: relative;

                .swiper_img {
                    width: 100%;
                    height: 420px;
                }

                .cont {
                    width: 282px;
                    height: 88px;
                    padding-left: 29px;
                    padding-top: 19px;
                    box-sizing: border-box;
                    background: linear-gradient(-30deg, #004b9d, #0079cd);
                    position: absolute;
                    left: 0;
                    bottom: 0;

                    .name {
                        font-size: 24px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #ffffff;
                        line-height: 24px;
                    }

                    .workingExperience {
                        width: 240px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #ffffff;
                        line-height: 16px;
                        margin-top: 12px;
                    }
                }

                .introduction_box {
                    width: 280px;
                    height: 420px;
                    background: rgba(255, 255, 255, 0.9);
                    position: absolute;
                    top: 0;
                    left: 0;
                    padding: 39px 26px;

                    .lecturer_name {
                        font-size: 24px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #333333;
                        line-height: 24px;
                    }

                    .line {
                        width: 44px;
                        height: 2px;
                        background: #ff881e;
                        margin-top: 14px;
                    }

                    .instructor_experience {
                        height: 280px;
                        overflow: hidden;
                        overflow-y: scroll;
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        line-height: 24px;
                        margin-top: 18px;
                    }

                    .instructor_experience::-webkit-scrollbar {
                        display: none;
                    }
                }
            }
        }
    }

    .module5 {
        height: 863px;

        .title {
            font-size: 36px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            color: #333333;
            line-height: 36px;
            padding-top: 98px;
            margin-bottom: 50px;
        }

        .contact {
            .fl {
                width: 445px;
                height: 560px;
                padding-top: 66px;
                background: #f4f5f5;

                .list:nth-child(1) {
                    .icon_img {
                        width: 51px;
                        height: 51px;
                        margin-right: 23px;
                    }
                }

                .list:nth-child(2) {
                    .icon_img {
                        width: 52px;
                        height: 44px;
                        margin-right: 25px;
                    }
                }

                .list:nth-child(3) {
                    .icon_img {
                        width: 52px;
                        height: 52px;
                        margin-right: 26px;
                    }
                }

                .list:nth-child(4) {
                    .icon_img {
                        width: 50px;
                        height: 56px;
                        margin-right: 23px;
                    }
                }

                .list {
                    padding-left: 50px;
                    box-sizing: border-box;
                    margin-bottom: 55px;

                    .icon_img {
                        width: 51px;
                        height: 51px;
                    }

                    .contact_way {
                        width: 286px;
                        font-size: 22px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #003c7e;
                        line-height: 30px;
                    }
                }
            }

            .fr {
                .icon_map {
                    width: 780px;
                    height: 560px;
                }

                .icon_map::-webkit-scrollbar {
                    display: none;
                }
            }
        }
    }

    .school_imgs {
        position: relative;

        img {
            width: 548px;
            height: 358px;
        }

        video {
            width: 548px;
            height: 358px;
        }

        .icon_video {
            width: 54px !important;
            height: 54px !important;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -29px;
            margin-left: -29px;
        }
    }
}
</style>
<style lang="scss">
strong {
    display: block;
    margin-right: 4px;
}

.swiper-button-prev:after,
.swiper-button-next:after {
    content: none;
}
</style>