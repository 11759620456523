<template>
    <div class="teaching_environment_page">
        <div class="teaching_environment" :class="type == '关于我们' ? 'isAboutUs' : ''">
            <div class="content">
                <div class="brand_endorsement_box display column">
                    <img v-if="type != '关于我们' && type != '产业工人培训'" src="@/assets/img/home/6.png" alt="" />

                    <div>
                        <div class="brand_endorsement_title display">
                            {{ title }}
                        </div>
                        <div class="brand_endorsement_cont display">
                            {{ titleDescribe }}
                        </div>
                    </div>
                </div>
                <div class="classify_content display">
                    <div class="classify_list display pointer" :class="classifyIndex == index ? 'classifyActive' : ''"
                        v-for="(item, index) in list" :key="index" @click="changeclassify(item, index)">
                        {{ item.name }}
                    </div>
                </div>
                <div class="module_banner">
                    <el-carousel :interval="5000" arrow="always">
                        <el-carousel-item v-for="(item, index) in courseList" :key="index">
                            <img class="pointer" :src="item.environmentUrl" alt="" />
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="btn display pointer" @click="service">
                    立即预约实地体验 >>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        type: {},
        title: {},
        titleDescribe: {},
        list: {},
        courseList: {},
    },
    data() {
        return {
            classifyIndex: 0,
            courseData: [],
        }
    },
    created() { },
    methods: {
        // 切换教学环境分类
        changeclassify(item, index) {
            this.classifyIndex = index
            this.courseList = item.value
        },
        //联系客服
        service() {
            document.getElementById('nb-frame-chat-box').style.display = 'block'
        },
    },
}
</script>
<style lang="scss" scoped>
.teaching_environment_page {
    background: url('../assets/img/newHome/bg1.png') no-repeat;
    background-size: 100% 100%;

    .isAboutUs {
        background: white;
    }

    .teaching_environment {
        height: 1081px;
        padding-top: 96px;

        .brand_endorsement_box {
            img {}

            .brand_endorsement_title {
                font-size: 28px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #333333;
                line-height: 26px;
                margin-bottom: 15px;
            }

            .cont_text {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #666666;
                margin-top: 12px;
            }
        }

        .classify_content {
            margin-top: 35px;
            padding: 0px 20px;
            box-sizing: border-box;

            .classify_list {
                height: 40px;
                border-radius: 20px;
                padding: 0px 20px;
                color: #333333;
                font-size: 16px;
                line-height: 16px;
            }

            .classifyActive {
                background: #ff881e !important;
                border: 1px solid #ff881e !important;
                color: white !important;
            }
        }

        .module_banner {
            margin-top: 24px;
        }

        .btn {
            width: 248px;
            height: 50px;
            background: #ff881e;
            border-radius: 5px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            margin: 0 auto;
            margin-top: 56px;
        }

        ::v-deep.el-carousel__container {
            height: 586px;
        }

        ::v-deep.el-carousel {
            .el-carousel__container {
                .el-carousel__arrow {
                    width: 48px;
                    height: 48px;
                    font-size: 24px;
                    background: rgba(51, 51, 51, 0.5);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}
</style>